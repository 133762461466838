import sign from 'jwt-encode'
import config from 'utils/config'

// This is a minimal API auth configuration because the charts will be used
// in a internal dashboard. In a public application we should integrate hasura
// with a proper authentication system like firebase.

const FAKE_USER_ID = 'fake-user-123'
const JWT_KEY = config.charts.hasuraJWTKey
const HASURA_ROLE = 'user'
const HASURA_CUSTOM_CLAIMS = {
  'https://hasura.io/jwt/claims': {
    'x-hasura-default-role': HASURA_ROLE,
    'x-hasura-allowed-roles': [HASURA_ROLE],
    'x-hasura-user-id': FAKE_USER_ID,
  },
}

export default {
  getToken() {
    const payload = {
      sub: FAKE_USER_ID,
      name: 'Fake User',
      iat: Math.floor(Date.now() / 1000),
      ...HASURA_CUSTOM_CLAIMS,
    }
    return sign(payload, JWT_KEY, { algorithm: 'RS256' })
  },
}
