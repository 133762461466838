const COUNT_ALL_TEMPLATE = `
query ($from: Timestamp!, $to: Timestamp!, $page_ids: String!) @cached {
  {{QUERYNAME}}(
    where: {
      date: {_gte: $from, _lt: $to}
    },
    args: {page_ids: $page_ids},
  ) {
    count
    date
    page_id
  }
}`

const COUNT_BY_MODE_TEMPLATE = `
query ($from: Timestamp!, $to: Timestamp!, $page_ids: String!) @cached {
  {{QUERYNAME}}(
    where: {
      date: {_gte: $from, _lt: $to}
    },
    args: {page_ids: $page_ids},
  ) {
    count
    date
    mode
    page_id
  }
}`

function getQueryName({ unit, groupByMode }) {
  let name = null
  if (unit === 'daily') {
    name = 'SimulationsDailyCount'
  } else if (unit === 'monthly') {
    name = 'SimulationsMonthlyCount'
  } else {
    throw new Error('Invalid unit')
  }

  if (groupByMode) {
    name += 'ByMode'
  }

  return name
}

function getCountQuery({ unit, groupByMode = false }) {
  const queryTemplate = groupByMode
    ? COUNT_BY_MODE_TEMPLATE
    : COUNT_ALL_TEMPLATE
  const queryName = getQueryName({ unit, groupByMode })
  const query = queryTemplate.replace('{{QUERYNAME}}', queryName)

  return { query, queryName }
}

export { getCountQuery }
