import { Client, cacheExchange, fetchExchange, useQuery } from 'urql'
import config from 'utils/config'
import { getCountQuery } from './toothboothApiQueries'
import apiAuth from './apiAuth'

const TOOTHBOOTH_GRAPHQL_API = config.charts.graphAPIUrl

function createClient() {
  // const token = '';
  return new Client({
    url: TOOTHBOOTH_GRAPHQL_API,
    exchanges: [cacheExchange, fetchExchange],
    fetchOptions: () => {
      const token = apiAuth.getToken()
      return {
        headers: { Authorization: token ? `Bearer ${token}` : '' },
      }
    },
  })
}

function useGetCount({
  from,
  to,
  pageIds,
  groupByMode = false,
  unit = 'daily',
}) {
  if (from === undefined || to === undefined || pageIds === undefined) {
    throw new Error('from, to, and pageId are required')
  }
  if (!Array.isArray(pageIds) || pageIds.length === 0) {
    throw new Error('pageIds must be a non-empty array')
  }
  if (unit !== 'daily' && unit !== 'monthly') {
    throw new Error('unit must be "daily" or "monthly"')
  }
  const { query, queryName } = getCountQuery({ unit, groupByMode })
  const responseRoot = queryName
  const variables = {
    from,
    to,
    page_ids: pageIds.join(','),
  }
  const [result] = useQuery({
    query,
    variables,
  })

  const { data, fetching, error } = result

  if (fetching || error) return result

  return { data: data[responseRoot] }
}

const client = createClient()
export { client, useGetCount }
