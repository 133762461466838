import { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { actions as appActions } from '../modules/app.module'
import { actions as orderActions } from '../modules/order.module'
import { actions as dashboardActions } from '../modules/dashboard.module'

class Connector extends Component {
  render() {
    const { state, actions, children } = this.props

    return children({ state, actions })
  }
}

const mapStateToProps = (state) => ({ state })
const mapDispatchToProps = (dispatch) => {
  const actionList = [
    // label: String, value: Object
    { label: 'app', value: appActions },
    { label: 'order', value: orderActions },
    { label: 'dashboard', value: dashboardActions },
    // add more actions here
  ]

  return {
    actions: actionList.reduce(
      (prev, cur) => ({
        ...prev,
        [cur.label]: bindActionCreators(cur.value, dispatch),
      }),
      {},
    ),
  }
}

Connector.propTypes = {
  state: PropTypes.shape({}).isRequired,
  actions: PropTypes.shape({}).isRequired,
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.func])
    .isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Connector)
